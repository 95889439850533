import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { default as VexDialog } from 'vex-dialog';
import Vex from 'vex-js';

import { App } from 'src/App';
import { CONFIG } from 'src/config';
import 'src/scss/App.scss';

// Vex config
Vex.defaultOptions.className = 'vex-theme-flat-attack';
Vex.registerPlugin(VexDialog);
window.vex = Vex;

//----------------------------------------------------------------------------------------------------------------------------
// Sentry
//----------------------------------------------------------------------------------------------------------------------------
window.config = CONFIG;

const sentrySettings = {};

if (import.meta.env.VITE_SENTRY_RELEASE) {
  sentrySettings.release = import.meta.env.VITE_SENTRY_RELEASE;
  window._release_version = sentrySettings.release;
}

if (import.meta.env.VITE_SENTRY_ENV) {
  sentrySettings.environment = import.meta.env.VITE_SENTRY_ENV;
  window._deploy_env = sentrySettings.environment;
}

if (import.meta.env.VITE_SENTRY_DSN) {
  sentrySettings.dsn = import.meta.env.VITE_SENTRY_DSN;
} else if (window.config.env === 'prod') {
  sentrySettings.dsn = 'https://d1c31551e1e84ad0b605991c73ace1c8@o61992.ingest.sentry.io/293905';
}

window.Sentry = Sentry;

if (sentrySettings.dsn) Sentry.init(sentrySettings);

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <App sentryClient={Sentry} />
  </StrictMode>
);
